import React from 'react';
import { Container } from 'react-bootstrap';

export default function NotFound() {
  return (
    <Container className="pt-3">
      <h3>Sorry, page not found!</h3>
    </Container>
  );
}
